// require("../../styles/airlines.css")
import React from "react"
import { CSSTransitionGroup } from 'react-transition-group'
import { connect } from "react-redux"
import moment from 'moment'
import { fetchSearchHistoryData } from "../../../actions/channel/channel"
import SearchHistory from '../../../components/channelv2/SearchHistory'
import { getSearchHistoryData, clearSearchHistoryData } from '../../../actions/common/searchDataStorageHelper'
import { smoothAnimationSwitchSelector } from "../baseSelector"
import { CHANNEL_UBT_KEY_TYPES } from '../../../ubt.v2/channel'

class SearchHistoryContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showHistory: false,
            searchDatas: null,
            storageData: [],
            hasAnimate: false
        }
        this.clearHistoryData = this.clearHistoryData.bind(this)
    }

    componentDidMount() {
        let historyData = getSearchHistoryData()

        if (historyData && historyData.length) {
            let requestParams = []
            historyData.forEach((data, index) => {
                let param = {}
                // 获得的存储数据可能为0
                let paseCityCode = (city) => {
                    if (city.Code) {
                        let code = city.Code
                        if (code.indexOf('0') > 0) {
                            return {
                                code: code.substr(0, code.indexOf('0')),
                                airport: true
                            }
                        } else {
                            return {
                                code: code,
                                airport: city.type && city.type == 'airport' || false
                            }
                        }
                    }
                    return null
                },
                    selectedCity = data.selectedCity || null,
                    dCity = selectedCity && selectedCity.owDCity ? selectedCity.owDCity : null,
                    aCity = selectedCity && selectedCity.rtACity ? selectedCity.rtACity : null,
                    dCityName = dCity && dCity.text,
                    aCityName = aCity && aCity.text,
                    resDCity = paseCityCode(dCity),
                    resACity = paseCityCode(aCity),
                    dCityCode = resDCity && resDCity.code,
                    aCityCode = resACity && resACity.code,
                    arrivalIsAirport = resACity && resACity.airport,
                    departureIsAirport = resDCity && resDCity.airport,
                    adult = data.adultCount,
                    child = data.childCount,
                    infant = data.infantCount
                param = {
                    cabin: data.classGrade,
                    price: data.price,
                    flightList: [],
                    id: index,
                    flightWay: data.flightWay,
                    singleAdult: !!(adult == 1 && child == 0 && infant == 0),
                    isDomestic: !!data.isDomestic
                }

                let getUrlCityCode = (city) => {
                    if (city.airport) {
                        return city.code + '0'
                    } else {
                        return city.code
                    }
                }
                let dCityUrlCode = getUrlCityCode(resDCity)
                let aCityUrlCode = getUrlCityCode(resACity)

                if (data.flightWay == "OW" && dCityCode && aCityCode) {
                    let dapartDate = moment(data.owDDate).format('YYYY-MM-DD')
                    param.searchUrl = `oneway-${dCityUrlCode}-${aCityUrlCode}?depdate=${dapartDate}&cabin=${data.classGrade}&adult=${adult}&child=${child}&infant=${infant}`.toLowerCase()
                    param.flightList.push({
                        departureCode: dCityCode,
                        departureName: dCityName,
                        arrivalCode: aCityCode,
                        arrivalName: aCityName,
                        departureDate: dapartDate,
                        arrivalIsAirport: arrivalIsAirport,
                        departureIsAirport: departureIsAirport
                    })
                } else if (data.flightWay == "RT" && dCityCode && aCityCode) {
                    let dapartDate = moment(data.owDDate).format('YYYY-MM-DD')
                    let arrivalDate = moment(data.rtADate).format('YYYY-MM-DD')

                    param.searchUrl = `round-${dCityUrlCode}-${aCityUrlCode}?depdate=${dapartDate}_${arrivalDate}&cabin=${data.classGrade}&adult=${adult}&child=${child}&infant=${infant}`.toLowerCase()
                    param.flightList.push({
                        departureCode: dCityCode,
                        departureName: dCityName,
                        arrivalCode: aCityCode,
                        arrivalName: aCityName,
                        departureDate: dapartDate,
                        arrivalIsAirport: arrivalIsAirport,
                        departureIsAirport: departureIsAirport
                    },
                        {
                            departureCode: aCityCode,
                            departureName: aCityName,
                            arrivalCode: dCityCode,
                            arrivalName: dCityName,
                            departureDate: arrivalDate,
                            arrivalIsAirport: departureIsAirport,
                            departureIsAirport: arrivalIsAirport
                        })
                }
                requestParams.push(param)
            });
            fetchSearchHistoryData(requestParams, (res) => {
                if (res && res.data && res.data.length) {
                    this.setState({
                        showHistory: true,
                        searchDatas: res.data,
                        storageData: requestParams
                    })
                }
            })
        }
        this.setState({
            hasAnimate: true
        })
    }

    clearHistoryData() {
        clearSearchHistoryData()
        this.setState({
            searchDatas: null,
            storageData: [],
            showHistory: false
        })
        CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({ tag: { type: 'clearSearchHistory' } })

    }

    render() {
        const { smoothAnimationSwitch } = this.props
        const {hasAnimate} = this.state

        const childEl = this.state.showHistory ? <SearchHistory clearSearchHistoryData={this.clearHistoryData}  {...this.state} /> : null

        return smoothAnimationSwitch && !hasAnimate ? (
            <CSSTransitionGroup
                transitionName='search-history-animate'
                transitionEnterTimeout={300}
                    transitionLeave={true}
                    transitionLeaveTimeout={250}>
                {childEl}
            </CSSTransitionGroup>
        ) : childEl
    }
}


const mapStateToProps = (state) => {
    return {
        smoothAnimationSwitch: smoothAnimationSwitchSelector(state)
    }
}

export default connect(mapStateToProps, null)(SearchHistoryContainer)
