import React from 'react'
import moment from 'moment'
import qs from 'qs';
import { formatWeek } from '../../utils/dateHelper'
import {
    UbtBoundary,
    ChannelUbtGenerator,
    CHANNEL_UBT_GROUP_TYPES,
    CHANNEL_UBT_KEY_TYPES
} from '../../ubt.v2/channel'
import { PAGE_LIST_URL } from '../../constants/common';

let SearchHistory = ({ searchDatas, storageData, clearSearchHistoryData }) => {
    let getDate = data => {
        if (data.flightList && data.flightList.length && data.flightList.length == 2) {
            return (
                <span className='date'>
                    <span className='go'>
                        {moment(data.flightList[0].departureDate).format('MM-DD')} 去
                    </span>
                    <span className='back'>
                        {moment(data.flightList[1].departureDate).format('MM-DD')} 回
                    </span>
                </span>
            )
        } else {
            return (
                <span className='date'>
                    <span className='go'>
                        {moment(data.flightList[0].departureDate).format('YYYY-MM-DD')}
                    </span>
                    <span className='back'>
                        {formatWeek(moment(data.flightList[0].departureDate))}
                    </span>
                </span>
            )
        }
    }

    let getComparePrice = (data, calendarPrice) => {
        if (calendarPrice && data.price && calendarPrice < data.price) {
            return (
                <span className='compare'>
                    <span className='lower'>
                        <i className='iconf-price-lower'>&#xe673;</i>
                        比上次浏览下降￥{data.price - calendarPrice}
                    </span>
                </span>
            )
        } else if (calendarPrice && data.price && calendarPrice > data.price) {
            return (
                <span className='compare'>
                    <span className='higher'>
                        <i className='iconf-price-higher'>&#xe66d;</i>
                        比上次浏览上涨￥{calendarPrice - data.price}
                    </span>
                </span>
            )
        } else if (calendarPrice && data.price && calendarPrice == data.price) {
            return (
                <span className='compare'>
                    <span className='no-change'>价格稳定</span>
                </span>
            )
        }

        return null
    }
    let getPrice = data => {
        if (data.calendarPrice) {
            return (
                <span className='price'>
                    <span className='price-tag'>￥</span>
                    <span className='price-amoumt'>{data.calendarPrice}</span>起
                </span>
            )
        }
        return (
            <span className='search-price'>
                <span className='search-text'>查最新价</span>
                <span className='iconf-scroll'>&#xe600;</span>
            </span>
        )
    }
    let getCityName = text => {
        let cityLength = text.lastIndexOf('(') > 0 ? text.lastIndexOf('(') : text.length
        return text.substr(0, cityLength)
    }
    let getAirLine = (id, showArrow) => {
        let sData = storageData && storageData[id]
        if (sData) {
            let flightWay = sData[`flightWay`]
            let dCityName = sData.flightList[0][`departureName`]
            let aCityName = sData.flightList[0][`arrivalName`]
            return (
                <span className={`flight`}>
                    <span className='depart'>{getCityName(dCityName)}</span>
                    {showArrow === false ? (
                        <span className='to'>-</span>
                    ) : flightWay == 'RT' ? (
                        <i className='iconf-rt-arrow'></i>
                    ) : (
                                <i className='iconf-ow-arrow'></i>
                            )}
                    <span className='arrival'>{getCityName(aCityName)}</span>
                </span>
            )

        }
    }
    let onSearchClick = id => {
        let url = storageData && storageData[id] && storageData[id].searchUrl
        CHANNEL_UBT_KEY_TYPES.HISTORY_SEARCH_RECOMMEND_CLICK.value.process({ id })
        let channelParams = '',
        mainChannelParams = ''
        if (url) {
            const query = qs.parse(
                window.location.search &&  window.location.search[0] === '?' ?  window.location.search.substring(1) :  window.location.search,
            ),
            { subchannelid, channelid } = query;
            channelParams = subchannelid ? `&subchannelid=${subchannelid}` : '';
            mainChannelParams = channelid ? `&channelid=${channelid}` : '';
            window.location.href = `${PAGE_LIST_URL}/${url}${channelParams}${mainChannelParams}&source_module=recommend_card`
        }
    }
    return (
            <div className='search-history'>
                <div className='title-action'>
                    <h2>您搜索过的机票</h2>
                    <div className='clear-block' onClick={clearSearchHistoryData}>
                        <i className='iconf-delete'>&#xe677;</i>
                        <span className='clear-text'>清空历史</span>
                    </div>
                </div>
                <ul className='search-history-list'>
                    {searchDatas && searchDatas.map((data, index) => {
                        return (
                            <UbtBoundary
                                key={index}
                                tracker={ChannelUbtGenerator(
                                    CHANNEL_UBT_GROUP_TYPES.HISTORY_SEARCH_RECOMMEND,
                                    CHANNEL_UBT_KEY_TYPES.HISTORY_SEARCH_RECOMMEND_SHOW,
                                    {
                                        sData: storageData[data.id],
                                        calendarPrice: data.calendarPrice
                                    }
                                )}
                            >
                                <li
                                    className={`rt-item ${
                                        !data.calendarPrice ? 'item-none-price' : ''
                                        }`}
                                    onClick={() => {
                                        onSearchClick(data.id)
                                    }}
                                >
                                    <div className='item-top'>
                                        {getAirLine(data.id)}
                                        {getPrice(data)}
                                    </div>
                                    <div className='item-main'>
                                        {getDate(storageData[data.id])}
                                        {getComparePrice(storageData[data.id], data.calendarPrice)}
                                    </div>
                                </li>
                            </UbtBoundary>
                        )
                    })}
                </ul>
            </div>
    )
}

export default SearchHistory
